import React from 'react';

import Card from './card';
import { StaticQuery, graphql } from 'gatsby'


const listCards = () => {


	return (
        <StaticQuery
    query={graphql`
      query CardsQuery {
        site {
          siteMetadata {
            cards_agence { title, subtitle, slug, bgColor }
            cards { title, subtitle, slug, bgColor }
            cards_mission { title, subtitle, slug, bgColor }
            cards_avenir { title, subtitle, slug, bgColor }

          }
        }
      }
    `}
    render={data => (
        <div style={{ padding: "1rem", backgroundColor: "#3fa1da3b"}}>

        {/* L'AGENCE */}

        <div className="columns" style={{flexWrap: "wrap", justifyContent: "space-around"}}>
	      {
                data.site.siteMetadata.cards_agence.map((card, i) => {
                    return (
                        
                      <div className="column">
                        <Card title={card.title} subtitle={card.subtitle} key={i} slug={card.slug} bgColor={card.bgColor}/>
                      </div>
    
                );
                })
            }
            </div>
        

            {/* LES MISSIONS */}

            <div>
            <h2 className="title is-2 bg_h2_titles" style={{marginBottom:"2%"}}> Les principales missions </h2>
            </div>
           <div className="columns" style={{flexWrap: "wrap", justifyContent: "space-around"}}>
	      {
                data.site.siteMetadata.cards_mission.map((card, i) => {
                    return (
                        
                      <div className="column">
                        <Card title={card.title} subtitle={card.subtitle} key={i} slug={card.slug} bgColor={card.bgColor}/>
                      </div>
    
                );
                })
            }
            </div>

            {/* ALLER PLUS LOIN */}

          <div>
            <h2 className="title is-2 bg_h2_titles" style={{marginBottom:"2%"}}> Pour aller plus loin </h2>
          </div>
           <div className="columns" style={{flexWrap: "wrap", justifyContent: "space-around"}}>
	      {
                data.site.siteMetadata.cards.map((card, i) => {
                    return (
                        
                      <div className="column">
                        <Card title={card.title} subtitle={card.subtitle} key={i} slug={card.slug} bgColor={card.bgColor}/>
                      </div>
    
                );
                })
            }
            </div>

          {/* VERS L'AVENIR ET AU-DELÀ */}

          <div>
            <h2 className="title is-2 bg_h2_titles" style={{marginBottom:"2%"}}> Vers l'avenir et au-delà </h2>
          </div>
           <div className="columns" style={{flexWrap: "wrap", justifyContent: "space-around"}}>
	      {
                data.site.siteMetadata.cards_avenir.map((card, i) => {
                    return (
                        
                      <div className="column">
                        <Card title={card.title} subtitle={card.subtitle} key={i} slug={card.slug} bgColor={card.bgColor}/>
                      </div>
    
                );
                })
            }
            </div>

        </div>
        )}
        />  
  );
  
}

export default listCards;
