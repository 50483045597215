import React, {useState} from 'react';

import ListCards from '../components/cards/listCards';
import Layout from '../components/layout';

const ALaCarte = () => {

    return (
	<Layout>
	    <ListCards />
	</Layout>
  );
  
}

export default ALaCarte;
