import React from 'react'

import {BsBook} from "react-icons/bs"
import { Link } from 'gatsby';


const Cards = (props) => 
(
<div>
<div class="card" style={{borderRadius:"20px"}}>
  <div class="card-content" style={{textAlign: "center", wordBreak: "keep-all !important"}}>
  <Link to={"/articles/" + props.slug}>
   <p class="title">
      {props.title}
    </p>
    
    <p class="subtitle">
      {props.subtitle}
    </p>
    </Link>
  </div>
<div>
  <Link className="button is-medium is-uppercase home_buttons" style={{ backgroundColor: props.bgColor }} to={"/articles/" + props.slug}> {/*Lire <BsBook style={{color: "white"}} size={30}/>*/} </Link>
  </div>    
</div> 
      
</div>
)

export default Cards
